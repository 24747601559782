.richtext-blocks {
  .row-full-width {
    max-width: 100%;
    margin: 0 -20px !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-sans-serif;
  }
}
