.block.numbersBlock {
  color: #fff;

  .full-width {
    height: auto;
  }

  > .ui.basic.button.delete-button {
    top: -0.2rem;
    padding: 0.2rem;
    border-radius: 100%;
    line-height: 1rem;

    svg:not(:hover) {
      fill: #fff !important;
    }

    &:hover,
    &:active {
      background-color: #fff !important;
    }
  }

  .background-image {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
      content: '';
    }
  }

  .single-block.subblock-edit {
    .dragsubblock {
      top: -1.2rem;
      left: -0.75rem;
    }

    .ui.basic.icon.button.delete-button {
      top: -1.2rem;
    }
  }

  .block-header {
    .icons {
      margin-bottom: 0.5rem;

      .icon {
        width: auto;
        height: 2.2rem;
      }

      .icon + .icon {
        margin-left: 0.8rem;
      }

      &.placeholder {
        color: $placeholder;

        .placeholder-text {
          margin-top: 0.5rem;
          font-size: 0.9rem;
        }
      }
    }

    .title {
      font-size: 2.25rem;
      font-weight: bold;
      line-height: 2.8rem;
    }
  }

  .block-number {
    .subblock-title {
      font-size: 5.5rem;
      font-weight: 500;
      line-height: 1em;

      .DraftEditor-root
        .DraftEditor-editorContainer
        .public-DraftEditor-content
        span,
      .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
        font-family: $font-family-monospace !important;
      }
    }

    .subblock-text {
      font-size: 1.35rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, xl)}) {
    .block-header {
      .icons {
        .icon {
          height: 2rem;
        }
      }

      .title {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }

    .block-number {
      .subblock-title {
        font-size: 5rem;
      }

      .subblock-text {
        font-size: 1.2rem;
        line-height: 1.35rem;
      }
    }
  }
}
