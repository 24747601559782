.in-evidence {
  .in-evidence-cards-wrapper {
    display: -ms-grid;
    display: grid;
    gap: 1.3rem;
    -ms-grid-columns: 1fr 1.3rem 1fr 1.3rem 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .card-wrapper {
    &:first-child {
      grid-column: 1;
      grid-row: 1 / 3;
    }
  }

  // smartphone
  @media (max-width: 585px) {
    .in-evidence-cards-wrapper {
      .card-wrapper {
        width: 100%;
        margin: 0px;
      }
      display: block;
      height: 100%;
    }
  }
  // tablet
  @media (max-width: 1367px) and (min-width: 586px) {
    .in-evidence-cards-wrapper {
      grid-template-columns: auto auto;
    }
  }
}
