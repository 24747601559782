//mobile
.it-header-center-wrapper {
  // height: $dvt-header-center-max-height;

  .it-header-center-content-wrapper {
    //right zone
    .it-right-zone {
      .it-socials {
        ul {
          .icon {
            color: $primary-text;
          }
        }
      }
    }
  }
}
