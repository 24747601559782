.simple-list-template ul,
.simple-list-skeleton-template ul {
  padding: 0;
  font-size: 0.9rem;
  line-height: 1rem;
  list-style: none;

  &.decoration-pointer {
    padding-left: 1.5rem;
    list-style: inherit;
    list-style-type: disc;
  }

  li {
    margin-bottom: 0.5rem;
    color: $primary;

    a {
      text-decoration: none;
    }
  }
}

.simple-list-skeleton-template {
  min-height: 7em;
}
