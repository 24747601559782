.PageHeaderWrapper {
  .header-infos {
    p {
      margin-bottom: 0.5rem;
    }
  }

  .header-image {
    max-width: 16em;
    max-height: 8em;
  }

  .link-list-wrapper .link-list {
    li a {
      margin-bottom: 0.9rem;
      line-height: 1.4rem;
    }

    li:last-child a {
      margin-bottom: 0;
    }
  }
}
