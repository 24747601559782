.it-header-slim-wrapper {
  .it-header-slim-wrapper-content {
    a {
      &.dropdown-toggle {
        &[aria-expanded='true'] {
          .rounded-icon {
            .icon {
              transform: none;
            }
          }
        }
      }
    }

    .logout {
      .icon {
        margin-right: 0.5rem;
        color: $primary;
      }
    }

    // Tablet horizontal / small desktop
    @media (min-width: #{map-get($grid-breakpoints, lg)}) {
      a {
        &.dropdown-toggle {
          .rounded-icon {
            .icon {
              margin: 0;
            }
          }
        }
      }
    }

    // Mobile / Tablet / Small desktop
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      flex-wrap: wrap;

      .it-header-slim-right-zone.header-slim-right-zone {
        flex: 1 0 auto;
        justify-content: flex-end;
      }
    }
  }
}
