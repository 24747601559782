.block.twitter_posts {
  &,
  .public-ui {
    .row-full-width {
      background-color: #1ea1f2;
      color: $white;
    }

    h2,
    a {
      color: #000;
    }

    svg {
      fill: darken($primary, 20);
    }

    .authors {
      margin-bottom: 1.5em;
      font-size: 0.8em;

      a {
        margin-right: 1em;
        text-decoration: none;

        &:hover {
          color: darken($primary, 20);
        }
      }
    }

    .slick-slider {
      .slick-list {
        padding-bottom: 2em;
        margin-bottom: 1em;
      }

      .slick-track {
        display: flex;
      }

      ul.slick-dots {
        margin-bottom: 0;
      }

      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        .slick-arrow.slick-prev {
          left: -20px;
        }

        .slick-arrow.slick-next {
          right: -20px;
        }
      }
    }

    .tweet {
      display: flex;

      overflow: hidden;
      height: auto;
      flex-direction: column;
      padding: 1.5em;
      margin: 0 0.5em;
      background-color: $white;
      color: $text-color;

      a {
        color: $link-color;
      }

      .author {
        display: flex;
        line-height: 1.3em;

        figure {
          margin-right: 0.65em;
        }

        .user-infos {
          .user-name {
            font-weight: bold;
          }

          .user-username {
            color: $neutral-1-a7;
            font-size: 0.8em;
            font-weight: 500;
          }

          .user-name a,
          .user-username a {
            color: #000;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .tweet-text {
        height: 10.5em;
        margin-bottom: 1em;

        overflow-x: hidden;
        overflow-y: scroll;

        text-overflow: ellipsis;
      }

      .date-time {
        margin-bottom: 1.5em;
        color: $neutral-1-a7;
        font-family: $font-family-monospace;
        font-size: 0.7em;
        text-transform: uppercase;
      }

      .numbers {
        display: flex;
        justify-content: space-between;
        color: $neutral-1-a7;

        .number {
          svg {
            width: 0.8em;
            margin-right: 0.4em;
          }
        }
      }
    }

    .skeleton-template {
      .authors {
        @extend %skeleton-animation;
        min-height: 2em;
        background-color: $skeleton-bg;
      }

      .tweet {
        @extend %skeleton-animation;

        .author {
          .user-infos {
            .user-name,
            .user-username {
              min-height: 1em;
              background-color: $skeleton-bg;
            }

            .user-name {
              min-width: 7em;
              margin-bottom: 0.5em;
            }

            .user-username {
              width: 6em;
            }
          }

          figure {
            width: 3em;
            height: 3em;
          }
        }

        .tweet-text {
          min-height: 6em;
          background-color: $skeleton-bg;
        }

        .date-time {
          min-height: 1em;
          background-color: $skeleton-bg;
        }

        .numbers {
          .number {
            min-width: 4em;
            min-height: 1em;

            background-color: $skeleton-bg;
          }
        }
      }
    }
  }
}
