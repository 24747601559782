.it-brand-wrapper {
  a {
    .icon {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
