/***
******************+++
Questo file definisce alcune variabili aggiuntive per design-comuni-plone-theme
******************+++
**/

//cms
$placeholder: #b8c6c9 !default;

//site
$primary: #589e9e !default;
$primary-text: #fff !default;
$secondary-text: #fff !default;
$tertiary: #be2816 !default;
$tertiary-text: #fff !default;
$highlight-search: #ff0;
$font-family-monospace-light: Roboto Mono Light;

$argument-icon-color: $tertiary !default;
$argument-icon-bg: #f5f6f7 !default;

// $dvt-header-center-max-height: 166px;
// $header-center-max-height-mob: 80px;

$dvt-navigation-v-padding: 15px;
// $navigation-h-padding: 24px;
