.block.image {
  .no-image-wrapper {
    .toolbar-inner {
      height: auto;

      .input {
        max-width: calc(100% - 16px);
      }

      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        flex-wrap: wrap;
      }
    }
  }
}
