.react-select__control {
  border-top: none !important;
  border-right: none !important;
  border-bottom: solid #435a70 1px !important;
  border-left: none !important;
  border-radius: 0px !important;
}

.react-select__control:focus {
  border-bottom: solid #435a70 1.25px !important;
  /* box-shadow: none !important; */
}

.react-select__control:hover {
  border-bottom: solid #435a70 1.25px !important;
  box-shadow: none !important;
}

.react-select__control--is-focused {
  border-style: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: solid #435a70 1.25px !important;
  border-left: none !important;
  box-shadow: none !important;
}

.react-select__indicator {
  fill: #435a70;
}

.react-select__placeholder {
  color: #435a70 !important;
}

.react-select__single-value {
  color: #435a70 !important;
  font-weight: bold;
}

.react-select__single-value:hover {
  color: black !important;
  font-weight: bold;
}
