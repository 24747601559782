.container .block {
  height: auto;

  .searchSections:not(.button) {
    width: 100%;
    height: auto;
    min-height: 280px;
    padding: 10px 50px;

    @media (max-width: #{map-get($grid-breakpoints, lg) - 1px}) {
      padding: 10px 6px;
    }

    color: $secondary-text;

    .searchSections-background {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: $secondary;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    h2.text-secondary {
      color: $secondary-text !important;
    }

    .searchContainer {
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0px 12%;
      margin: 56px 0;

      @media (max-width: #{map-get($grid-breakpoints, lg) - 1px}) {
        padding: 0px 10px;
      }

      input.inputSearch {
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        font-weight: 500;
        outline: 0;
        transition: 0.2s ease-out;
      }

      .searchbar {
        position: relative;
        height: 45px;
        padding: 5px 14px;

        button {
          position: absolute;
          top: 0;
          right: 0;

          display: flex;
          width: 50px;
          height: 100%;
          align-items: center;
          justify-content: center;
          border-width: 0px;

          background: $primary;

          &:hover,
          &:active {
            background-color: darken($primary, 7.5);
          }
        }
      }
    }
  }
}

.buttonsContainer {
  flex-wrap: wrap;

  button.btn.btn-sm {
    margin: 10px 10px 10px 0px;
  }
}
