.block.video_gallery {
  .full-width {
    height: 350px;
  }

  .volto-subblocks-wrapper {
    .full-width {
      height: 400px;
    }
  }

  .add-block-wrapper {
    text-align: center;
  }

  .channel {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    a {
      text-decoration: none;
    }
  }

  .video-wrapper {
    margin-bottom: 1.5em;

    .ui.embed {
      position: relative;
      width: auto;
      max-width: unset;
      height: unset;
      padding: 0 1em;
      background: transparent;

      > .embed {
        iframe {
          position: relative;
          height: 190px;
        }
      }

      img.placeholder {
        width: 100%;
        height: 190px;
        object-fit: cover;
      }

      .icon-play {
        position: absolute;
        z-index: 2;

        top: 50%;
        left: 50%;
        width: 3.6rem;
        height: 2.5rem;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 15px;

        color: $white;
        cursor: pointer;
        line-height: 2.5rem;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);

        svg {
          width: 1rem;
          height: 1rem;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: $primary;
          color: $primary-text;
        }
      }

      &.active {
        .icon-play,
        img.placeholder {
          display: none;
        }
      }
    }

    video {
      display: block;
      max-width: 300px;
      max-height: 150px;
      margin: 0 auto;
    }
  }

  .subblock-edit {
    padding-top: 1.3em;

    .dragsubblock {
      top: 0;
      left: 0;
    }

    .ui.basic.button.delete-button {
      top: 0;
      right: 6rem;
      left: unset;
    }
  }
}
