.block.testo_riquadro_immagine {
  .card {
    .card-body {
      .card-text {
        .DraftEditor-root * {
          font-family: 'Lora, Georgia, serif';
          font-size: 0.875rem;
        }
      }
    }
  }
}
