.customer-satisfaction {
  margin: 1rem 0;

  h2 {
    font-size: 1.2rem;
  }

  .buttons {
    margin-bottom: 0.5rem;

    .btn {
      margin-right: 0.5rem;
    }
  }

  .comment {
    margin: 1rem 0;

    textarea {
      height: 6rem;
    }
  }

  .submit-wrapper {
    margin: 2rem 0;
  }

  #cs-more {
    overflow: hidden;
    max-height: 0;
    visibility: hidden; //needed for ay11

    transition: 0.6s max-height ease;

    &[aria-expanded='true'] {
      visibility: visible; //needed for ay11
      max-height: 800px;
    }
  }
  .comment {
    @media (max-width: #{map-get($grid-breakpoints, lg) - 1px }) {
      margin-top: 3.5rem;
      .form-group {
        label {
          padding-bottom: 1rem;
        }
      }
    }
    .form-group {
      label {
        line-height: unset;
        white-space: unset;
      }
    }
  }
}
