.grid-gallery-template {
  .grid-gallery-grid {
    width: 100%;

    @supports (display: grid) {
      display: grid;
      grid-auto-flow: dense;
      grid-auto-rows: 50px;
      grid-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 600px) {
        grid-auto-rows: 160px;
        grid-template-columns: 1fr;
      }
    }

    @supports not (display: grid) {
      display: flex;
      flex-wrap: wrap;
    }

    .grid-gallery-item {
      position: relative;
      display: flex;
      overflow: hidden;
      height: 100%;
      align-items: flex-end;
      border-radius: 4px;
      box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.26);
      text-decoration: none;

      @supports (display: grid) {
        grid-column: span 1;

        &.item-0 {
          grid-column: 1;
          grid-row: span 8;
        }

        &.item-1 {
          grid-column: 1;
          grid-row: span 4;
        }

        &.item-2,
        &.item-4 {
          grid-column: 2;
          grid-row: span 5;
        }

        &.item-3 {
          grid-column: 2;
          grid-row: span 2;
        }

        &.item-5 {
          grid-column: 3;
          grid-row: span 4;
        }

        &.item-6 {
          grid-column: 3;
          grid-row: span 8;
        }

        @media (max-width: 767px) {
          &.item-5,
          &.item-6 {
            grid-column: auto;
          }
        }

        @media (max-width: 600px) {
          &.item-0,
          &.item-1,
          &.item-2,
          &.item-3,
          &.item-4,
          &.item-5,
          &.item-6 {
            grid-column: 1;
            grid-row: auto / span 1;
          }
        }
      }

      @supports not (display: grid) {
        width: calc((100% - 30px) / 3);
        margin: 0 5px 10px;

        a {
          min-height: 160px;
        }

        @media (max-width: 767px) {
          width: calc((100% - 20px) / 2);
        }

        @media (max-width: 600px) {
          width: 100%;
          margin: 0 0 10px;
        }
      }

      picture {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        img {
          min-width: 100%;
          min-height: 100%;
          object-fit: cover;
        }
      }

      h3 {
        position: relative;
        z-index: 1;
        width: 100%;
        align-self: flex-end;
        margin: 0;
        color: #fff;
        text-align: left;
      }

      a {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        color: #fff;
        text-decoration: none;

        &::before {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          content: '';
          opacity: 0.75;
          transition: opacity 0.3s ease;
        }

        &:hover,
        &:focus {
          h3 {
            text-decoration: underline;
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  .skeleton-template {
    .grid-gallery-grid {
      .grid-gallery-item {
        a {
          &::before {
            background: rgba(0, 0, 0, 0.2);
          }

          h3 {
            height: 1.5em;
          }
        }
      }
    }
  }
}
