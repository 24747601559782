.ribbon-card-template {
  .card {
    .dates {
      font-size: 0.8em;
      text-transform: uppercase;
    }

    &.card-big {
      .etichetta {
        position: absolute;
        top: 3rem;
        right: 0;
        left: 0;
        width: 100%;
        justify-content: inherit;
        padding: 0 3rem;

        .icon {
          margin-left: auto;
        }
      }

      .card-body {
        padding: 3.75rem 3rem 2.75rem;
      }
    }

    h3.card-title {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.44444rem;
    }

    &.show_detail_link {
      .card-title {
        a {
          color: $text-color;
          text-decoration: none;

          &:hover,
          &:active {
            color: $link-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
