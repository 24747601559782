.header-slim-right-zone {
  .parent-site-menu {
    font-size: 0.778em;

    li.nav-item {
      border-right: 1px solid $primary-text;

      a.nav-link {
        color: $primary-text;

        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          padding: 0.3rem;
        }
      }
    }

    // Tablet / desktop
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      flex-wrap: wrap;
      justify-content: flex-end;

      li.nav-item {
        display: flex;
        max-width: 10rem;
        align-items: center;
      }
    }
  }
}

.has-megamenu .navbar-collapsable {
  .parent-site-menu {
    flex-direction: column;

    &::before {
      width: 100%;
      max-width: 65px;
      height: 1px;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 1.5rem;
      background: rgba(92, 111, 130, 0.5);
      content: '';
    }

    li.nav-item {
      a {
        padding: 13px 24px;
        font-size: 16px;
        font-weight: 600;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Tablet / Mobile (Headerslim)
@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .header-slim-right-zone {
    .parent-site-menu {
      display: none;
    }
  }
}

// Tablet / Desktop (Megamenu)
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .has-megamenu .navbar-collapsable {
    .parent-site-menu {
      display: none;
    }
  }
}
