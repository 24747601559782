#trasparenza-page-content {
  margin-top: 4rem;
  margin-bottom: 4rem;
  .trasparenza-item-header {
    font-size: 1.6rem;
  }

  .trasparenza-second-level-wrapper {
    margin-left: 0.5rem;
    .trasparenza-item {
      font-size: 1.2rem;
    }
  }
}
