$space-left: 1rem;

.block.table-of-contents {
  // template for "Indice dei contenuti"
  .ui.bulleted.list {
    .item {
      display: flex;
      align-items: center;
      padding-bottom: 0.6rem;
      font-size: 0.9rem;
      list-style: none;

      a {
        display: inline-block;
        margin-left: 0.2rem;
        text-decoration: none;
      }

      &:not(.header-two)::before {
        position: relative;
        display: inline-block;
        width: 0.55rem;
        min-width: 0.55rem;
        height: 0.55rem;
        min-height: 0.55rem;
        border-top: 0.1rem solid $primary;
        border-right: 0.1rem solid $primary;
        margin-right: 1rem;
        content: '' !important;
        list-style: none;
        transform: rotate(45deg);
      }

      &.header-two {
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 600;

        &::before {
          width: 0;
          height: 0;
          border-top: 0.4rem solid transparent;
          border-bottom: 0.4rem solid transparent;
          border-left: 0.5rem solid $primary;
          margin-right: 0.8rem;
          content: '' !important;
          list-style: none;
        }
      }

      &.header-three {
        margin-left: $space-left;
      }

      &.header-four {
        margin-left: calc(#{$space-left} * 2);
      }

      &.header-five {
        margin-left: calc(#{$space-left} * 3);
      }

      &.header-six {
        margin-left: calc(#{$space-left} * 4);
      }
    }
  }
}
